/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Type } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';

export type DialogData = {
  dialogConfig: MatDialogConfig;
  navigateSettings?: {
    /** An array of URL fragments with which to construct the target URL. */
    commands?: any[];
    navigateAway?: (router: Router) => void;
  } & (
    | {
        /** Use to remove named outlet from URL */
        isRelativeToRoot: true;
      }
    | {
        /** Use to remove named outlet from URL */
        isRelativeToRoot?: false;
        /** Use to navigate more than one route back*/
        relativeToLevelsBack?: number;
      }
  );
};

export type DialogRoute = Route & { data: DialogData } & (
    | { component: Type<any> }
    | { loadComponent: () => Promise<Type<any> | { default: Type<any> }> }
  );
